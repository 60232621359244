import React, { Component } from 'react';
import MUIDataTable from 'mui-datatables';
import axios from 'axios';

import { openSnackbar } from '../Components/Snackbar';

const options = {
  customSort: (data, colIndex, order) => {
    return data.sort((a, b) => {
      a = a.data[colIndex] || '';
      b = b.data[colIndex] || '';
      if (order === 'asc') {
        return a.toString().localeCompare(b, undefined, { numeric: true });
      } else if (order === 'desc') {
        return b.toString().localeCompare(a, undefined, { numeric: true });
      } else {
        return a.toString().localeCompare(b, undefined, { numeric: true });
      }
    });
  },
  elevation: 0,
  filter: true,
  filterType: 'dropdown',
  print: false,
  responsive: 'stacked',
  rowsPerPage: 25,
  rowsPerPageOptions: [25, 50, 100],
  selectableRows: 'none',
};

class Scans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scans: [],
    };
  };

  componentDidMount() {
    this.getScanList();
  }

  getScanList = async () => {
    try {
      const scans = await axios.get('/api/scan');
      this.setState({ scans: scans.data });
    } catch (e) {
      openSnackbar({message: e.response.data, status: 'error'});
    }
  };

  render() {
    const { scans } = this.state;

    const columns = [
      {
        name: 'timestamp_scanned',
        label: 'Timestamp',
        options: {
          display: false,
          sortDirection: 'desc',
        },
      },
      {
        name: 'user_name',
        label: 'Username',
        options: {
          display: false,
        },
      },
      {
        name: 'device_name',
        label: 'Device',
        options: {
          display: false,
        },
      },
      {
        name: 'firstname',
        label: 'First Name',
      },
      {
        name: 'lastname',
        label: 'Last Name',
      },
      {
        name: 'title',
        label: 'Title',
      },
      {
        name: 'email',
        label: 'Email',
      },
      {
        name: 'phone',
        label: 'Phone',
      },
      {
        name: 'company',
        label: 'Company',
      },
      {
        name: 'address1',
        label: 'Address 1',
        options: {
          display: false,
        },
      },
      {
        name: 'address2',
        label: 'Address 2',
        options: {
          display: false,
        },
      },
      {
        name: 'city',
        label: 'City',
        options: {
          display: false,
        },
      },
      {
        name: 'state',
        label: 'State',
        options: {
          display: false,
        },
      },
      {
        name: 'zip',
        label: 'Zip',
        options: {
          display: false,
        },
      },
      {
        name: 'interest_level',
        label: 'Interest Level',
      },
      {
        name: 'contact_method',
        label: 'Contact Method',
      },
      {
        name: 'quick_note',
        label: 'Quick Note',
      }
    ];

    return (
      <MUIDataTable
        title={'Scan List'}
        data={scans}
        columns={columns}
        options={options}
      />
    );
  }
}

export default Scans;
