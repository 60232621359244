import React, { Component } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';
import axios from 'axios';

import Layout from './Views/Layout';

import Scans from './Routes/Scans';
import UserList from './Routes/UserList';
import UserAdd from './Routes/UserAdd';
import Profile from './Routes/Profile';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
    };
  }

  componentDidMount() {
    this.handleLoadUser();
  }

  handleLoadUser = async () => {
    try {
      const user = await axios
        .get('/auth/user');
      if (user.data) {
        this.setState({
          user: user.data,
        });
      } else {
        this.setState({
          user: '',
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <BrowserRouter>
        <Layout user={this.state.user}>
          <Switch>
            <Route exact path="/" component={Scans} />
            <Route path="/userList" component={UserList} />
            <Route path="/userAdd" component={UserAdd} />
            <Route path="/profile" component={Profile} />
          </Switch>
        </Layout>
      </BrowserRouter>
    );
  }
}

export default App;
