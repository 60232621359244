import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto';

import {
  MuiThemeProvider,
  CssBaseline,
} from '@material-ui/core';

import App from './App';
import Theme from './Views/Theme';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <Fragment>
    <MuiThemeProvider theme={Theme}>
      <CssBaseline />
      <App />
    </MuiThemeProvider>
  </Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
