import React, { Component, Fragment } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import {
  AppBar,
  Button,
  IconButton,
  Paper,
  TextField,
  Toolbar,
  Typography,
 } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import MenuIcon from '@material-ui/icons/Menu';
import LockOpenIcon from '@material-ui/icons/LockOpen';

import Menu, { toggleDrawer } from './Menu';
import Snackbar, { openSnackbar } from '../Components/Snackbar';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  list: {
    width: 250,
  },
  bodyPaper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  button: {
    margin: theme.spacing.unit,
    textAlign: 'right',
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
});

class Layout extends Component {
  state = {
    left: false,
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    const {
      username,
      password,
    } = this.state;
    try {
      await axios.post('/auth/login', {
        username: username,
        password: password,
      });
      window.location.reload();
    } catch (e) {
      openSnackbar({message: e.response.data, status: 'error'});
    }
  }

  handleLogout = async () => {
    await axios.get('/auth/logout');
    window.location.reload();
  }

  render() {
    const {
      classes,
      children,
      user,
    } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
              onClick={user ? toggleDrawer : null}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h5" color="inherit" className="classes.grow">
              Region VI Lead Retrieval
            </Typography>
          </Toolbar>
        </AppBar>
        <Menu
          userLevel={user.role}
          handleLogout={this.handleLogout}
        />
        <main className={classes.content}>
          <Paper className={classes.bodyPaper}>
            {user ? (
              <Fragment>
                {children}
              </Fragment>
            ) : (
              <Fragment>
                <Typography variant="h5">Log In</Typography>
                <form onSubmit={this.handleSubmit}>
                  <TextField
                    required
                    label="Username"
                    className={classes.textField}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    onChange={this.handleChange('username')}
                  />
                  <TextField
                    required
                    label="Password"
                    className={classes.textField}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    type="password"
                    onChange={this.handleChange('password')}
                  />
                  <div className={classes.button}>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                    >
                      <LockOpenIcon className={classes.leftIcon} />
                      Log In
                    </Button>
                  </div>
                </form>
              </Fragment>
            )}
          </Paper>
        </main>
        <Snackbar />
      </div>
    );
  }
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Layout);
