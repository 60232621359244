import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ExitIcon from '@material-ui/icons/ExitToApp';
import GroupIcon from '@material-ui/icons/Group';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import PersonIcon from '@material-ui/icons/Person';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

let toggleDrawerFn;

const styles = {
  list: {
    width: 250,
  },
};

class Menu extends Component {
  state = {
    left: false,
  };

  componentDidMount() {
    toggleDrawerFn = this.toggleDrawer;
  }

  toggleDrawer = () => {
    this.setState(prevState => ({
      left: !prevState.left,
    }));
  };

  render() {
    const {
      classes,
      userLevel,
      handleLogout,
    } = this.props;

    return (
      <SwipeableDrawer
        open={this.state.left}
        onClose={this.toggleDrawer}
        onOpen={this.toggleDrawer}
      >
        <div
          tabIndex={0}
          role="button"
          onClick={this.toggleDrawer}
          onKeyDown={this.toggleDrawer}
        >
          <div className={classes.list}>
          <List>
              <ListItem button key="Scans" component={Link} to="/">
                <ListItemIcon><PermContactCalendarIcon /></ListItemIcon>
                <ListItemText>View Scans</ListItemText>
              </ListItem>
           </List>
            <Divider />
            {userLevel === 'admin' &&
              <Fragment>
                <List>
                  <ListItem button key="Users" component={Link} to="/userList">
                    <ListItemIcon><GroupIcon /></ListItemIcon>
                    <ListItemText>View Users</ListItemText>
                  </ListItem>
                  <ListItem button key="UserAdd" component={Link} to="/userAdd">
                    <ListItemIcon><PersonAddIcon /></ListItemIcon>
                    <ListItemText>Add User</ListItemText>
                  </ListItem>
                </List>
                <Divider />
              </Fragment>
            }
            <List>
              {userLevel === 'admin' &&
                <ListItem button key="Profile" component={Link} to="/profile">
                  <ListItemIcon><PersonIcon /></ListItemIcon>
                  <ListItemText>Profile</ListItemText>
                </ListItem>
              }
              <ListItem button key="Logout" onClick={handleLogout}>
                <ListItemIcon><ExitIcon /></ListItemIcon>
                <ListItemText>Logout</ListItemText>
              </ListItem>
            </List>
          </div>
        </div>
      </SwipeableDrawer>
    );
  }
}

Menu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export function toggleDrawer() {
  toggleDrawerFn();
}

export default withStyles(styles)(Menu);