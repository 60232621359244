import React, { Component, Fragment } from 'react';
import axios from 'axios';
import {
  Button,
  TextField,
  Typography,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

import PersonAddIcon from '@material-ui/icons/PersonAdd';

import { openSnackbar } from '../Components/Snackbar';
import Devices from '../Components/Devices';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing.unit,
    textAlign: 'right',
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
});

class UserAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmationCode: '',
      devices: 3,
    };
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value, });
  };

  handleSubmit = async event => {
    event.preventDefault();

    const {
      confirmationCode,
      devices,
    } = this.state;
    try {
      const add = await axios.post('/api/user', {
        username: confirmationCode,
        limit: devices,
      });
      openSnackbar({message: add.data, status: 'success'});
      this.setState({
        confirmationCode: '',
        devices: 3,
      });
    } catch (e) {
      openSnackbar({message: e.response.data, status: 'error'});
    }
  };

  render() {
    const {
      classes,
    } = this.props;
    const {
      confirmationCode,
      devices,
    } = this.state;

    return (
      <Fragment>
        <Typography variant="h6">Add User</Typography>
        <form onSubmit={this.handleSubmit}>
          <TextField
            required
            value={confirmationCode}
            label="Confirmation Code"
            variant="outlined"
            margin="dense"
            fullWidth
            onChange={this.handleChange('confirmationCode')}
          />
          <Devices
            value={devices}
            handleChange={this.handleChange('devices')}
          />
          <div className={classes.button}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
            >
              <PersonAddIcon className={classes.leftIcon} />
              Submit
            </Button>
          </div>
        </form>
      </Fragment>
    );
  }
};

export default withStyles(styles)(UserAdd);