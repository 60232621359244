import React, { Component } from 'react';
import MUIDataTable from "mui-datatables";
import axios from 'axios';

import Devices from '../Components/Devices';
import { openSnackbar } from '../Components/Snackbar';

const options = {
  filter: true,
  selectableRows: 'none',
  filterType: 'dropdown',
  responsive: 'stacked',
  rowsPerPage: 10,
  rowsPerPageOptions: [10, 25, 50],
  elevation: 0,
  customSort: (data, colIndex, order) => {
    return data.sort((a, b) => {
      a = a.data[colIndex] || '';
      b = b.data[colIndex] || '';
      if (order === 'asc') {
        return a.toString().localeCompare(b, undefined, { numeric: true });
      } else if (order === 'desc') {
        return b.toString().localeCompare(a, undefined, { numeric: true });
      } else {
        return a.toString().localeCompare(b, undefined, { numeric: true });
      }
    });
  },
  setRowProps: (row) => {
    if (!row[4]) {
      return {
        style: {
          backgroundColor: 'rgb(223 107 107)',
        }
      }
    }
  }
};

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
  };

  componentDidMount() {
    this.getUserList();
  }

  getUserList = async () => {
    try {
      const users = await axios.get('/api/user');
      this.setState({ users: users.data });
    } catch (e) {
      openSnackbar({message: e.response.data, status: 'error'});
    }
  };

  updateUser = async (value, data, updateValue) => {
    const userIdCol = 0;
    try {
      const update = await axios.patch('/api/user/' + data[userIdCol], {
        limit: value,
      });
      updateValue(value);
      openSnackbar({message: update.data, status: 'success'});
    } catch (e) {
      openSnackbar({message: e.response.data, status: 'error'});
    }
  }

  render() {
    const { users } = this.state;

    const columns = [
      {
        name: 'id',
        label: 'User ID',
        options: {
          display: false,
        },
      },
      {
        name: 'username',
        label: 'Confirmation',
      },
      {
        name: 'organization',
        label: 'Company',
        options: {
          sortDirection: 'asc',
        },
      },
      {
        name: 'contact',
        label: 'Contact',
        options: {
          display: false,
        },
      },
      {
        name: 'email',
        label: 'Email',
        options: {
          display: false,
        },
      },
      {
        name: 'limit',
        label: 'Devices',
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Devices
                value={value}
                index={tableMeta.columnIdex}
                handleChange={event => {
                  this.updateUser(event.target.value, tableMeta.rowData, updateValue);
                }}
              />
            )
          },
        },
      },
      {
        name: 'error',
        label: 'Error',
        options: {
          display: false,
        }
      }
    ];

    return (
      <MUIDataTable
        title={"User List"}
        data={users}
        columns={columns}
        options={options}
      />
    );
  }
}

export default UserList;
