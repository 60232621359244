import { createMuiTheme } from '@material-ui/core';

const Theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTypography: {
      h6: {
        marginBottom: '1.5em',
      },
    },
    MUIDataTableBodyCell: {
      stackedCommon: {
        height: 'auto !important',
      },
    },
  },
});

export default Theme;