import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
    marginRight: 0,
    marginLeft: 0,
    minWidth: 100,
    fullWidth: true,
    display: 'flex',
  }
});

const options = [
  {
    value: 3,
    label: '3',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 12,
    label: '12',
  },
  {
    value: 15,
    label: '15',
  },
  {
    value: 0,
    label: 'Unlimited',
  },
  {
    value: -1,
    label: 'Disabled',
  },
];

class Devices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labelWidth: 0,
    };
  };

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    })
  }

  handleChange = name => event => {
    console.log(name, event.target);
    if ('name' in event.target) { name = event.target.name; }
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const {
      classes,
      value,
      handleChange,
    } = this.props;

    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel
          ref={ref => {
            this.InputLabelRef = ref;
          }}
          htmlFor="devices"
        >
          Number of devices
        </InputLabel>
        <Select
          value={value ? value : 0}
          onChange={handleChange}
          input={
            <OutlinedInput
              labelWidth={this.state.labelWidth}
              name="devices"
              id="devices"
            />
          }
        >
          {options.map((option, index) => (
            <MenuItem value={option.value} key={index}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

export default withStyles(styles)(Devices);
