import React, { Component, Fragment } from 'react';
import axios from 'axios';
import {
  Button,
  Divider,
  TextField,
  Typography,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

import SaveIcon from '@material-ui/icons/Save';

import { openSnackbar } from '../Components/Snackbar';
//import MuiReactSelect from '../Components/MuiReactSelect';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing.unit,
    textAlign: 'right',
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  divider: {
    margin: '2em 0',
  },
});

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirm: '',
      passwordError: false,
    };
  };

  componentDidMount() {
    this.handleLoadData();
  }

  handleLoadData = async () => {
    try {
      const profile = await axios.get('/api/profile');
      this.setState({
        ...profile.data,
      });
    } catch (e) {
      openSnackbar({ message: 'Error retrieving data! ' + e, status: 'error' });
    }
  }

  handleChange = name => event => {
    if (name === 'passwordConfirm' && event.target.value !== this.state.password) {
      this.setState({ passwordError: true, });
    } else {
      this.setState({ passwordError: false, });
    }
    this.setState({ [name]: event.target.value, });
  };

  handleSubmit = async event => {
    event.preventDefault();

    const {
      firstName,
      lastName,
      email,
      password,
      passwordConfirm,
    } = this.state;

    if (password && password !== passwordConfirm) {
      this.setState(prevState => ({
        passwordError: true,
      }));
      return openSnackbar({message: 'Passwords do not match!', status: 'error'});
    }

    try {
      const profile = await axios.patch('/api/profile', {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
      });
      openSnackbar({message: profile.data, status: 'success'});
      this.setState({
        password: '',
        passwordConfirm: '',
      });
      this.handleLoadData();
    } catch (e) {
      openSnackbar({message: e.response.data, status: 'error'});
    }
   };

  render() {
    const {
      classes,
    } = this.props;
    const {
      firstName,
      lastName,
      email,
      password,
      passwordConfirm,
      passwordError,
    } = this.state;

    return (
      <Fragment>
        <Typography variant="h6">My Profile</Typography>
        <form onSubmit={this.handleSubmit}>
          <TextField
            required
            value={firstName}
            label="First Name"
            variant="outlined"
            margin="dense"
            fullWidth
            onChange={this.handleChange('firstName')}
          />
          <TextField
            required
            value={lastName}
            label="Last Name"
            variant="outlined"
            margin="dense"
            fullWidth
            onChange={this.handleChange('lastName')}
          />
          <TextField
            required
            value={email}
            label="Email"
            variant="outlined"
            margin="dense"
            fullWidth
            onChange={this.handleChange('email')}
          />

          <Divider className={classes.divider} />

          <TextField
            value={password}
            label="Password"
            variant="outlined"
            margin="dense"
            fullWidth
            type="password"
            error={passwordError}
            onChange={this.handleChange('password')}
          />
          <TextField
            value={passwordConfirm}
            label="Confirm Password"
            variant="outlined"
            margin="dense"
            fullWidth
            type="password"
            error={passwordError}
            onChange={this.handleChange('passwordConfirm')}
          />
          <div className={classes.button}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
            >
              <SaveIcon className={classes.leftIcon} />
              Submit
            </Button>
          </div>
        </form>
      </Fragment>
    );
  }
};

export default withStyles(styles)(Profile);
